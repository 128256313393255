import React, {useCallback, useRef} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import AppButton from "../../common/components/app-button";
import {schemaAccountDetailsPartTwo} from "../constants/schema-account-details";
import {useUserApi} from "../hooks/use-user-api";
import {AdditionalAccountDetails} from "../models/interfaces/additional-account-details.interface";
import SelectorGender from "./selector-gender";
import {GenderEnum} from "../models/enums/gender.enum";
import TextFormField from "../../common/components/forms/text-form-field";
import SelectFormField from "../../common/components/forms/select-form-field";
import {useDictionaryApi} from "../hooks/use-dictionary-api";
import SkipStepVerification from "./ui/skip-step-verification";
import useAuth from "../../auth/hooks/use-auth";
import PhoneNumberFormField from "../../common/components/forms/phone-number-form-field";
import {convertEmptyStringsToNull} from "../../common/helpers/convert-empty-strings-to-null";
import {AuthenticatedUser} from "../../auth/models/interfaces/authenticated-user.interface";
import {useAnalytics} from "../hooks/use-analytics";

interface AdditionalAccountDetailsFormProps {
    onSuccess?: (data: AdditionalAccountDetails) => void
}

const AdditionalAccountDetailsForm = ({onSuccess}: AdditionalAccountDetailsFormProps) => {
    const {sendAdditionalInfo, loading} = useUserApi();
    const {currentUser, updateAuthenticationField} = useAuth();
    const {getCountries} = useDictionaryApi();
    const formRef = useRef<HTMLFormElement>(null);
    const methods = useForm<AdditionalAccountDetails>({
        resolver: yupResolver(schemaAccountDetailsPartTwo as any),
        mode: 'onSubmit',
        defaultValues: {
            lastname: currentUser?.lastname ?? "",
            firstname: currentUser?.firstname ?? "",
            genderId: currentUser?.genderId ?? GenderEnum.MALE,
            contactNumber: currentUser?.phoneNumber ?? "",
            countryId: null,
            areaCode: null,
        }
    });
    const values = methods.watch();
    const {sendRegisterAccInfo} = useAnalytics();
    const getCountriesMemoized = useCallback(() => getCountries(), []);

    const mapDataForm = (dataForm: AdditionalAccountDetails): AdditionalAccountDetails => {
        let data = convertEmptyStringsToNull(dataForm);
        if (!data['contactNumber']) {
            data['areaCode'] = null;
        } else {
            data['contactNumber'] = data['contactNumber'].replaceAll(" ", "");
        }
        return data
    }

    const handleSendBasicInfo = async () => {
        const isValid: boolean = await methods.trigger();
        if (!isValid) return;

        const data: AdditionalAccountDetails = mapDataForm(methods.getValues());
        try {
            await sendAdditionalInfo(data);
            const updatedUser: AuthenticatedUser = {
                ...currentUser!,
                firstname: data?.firstname ? data.firstname : currentUser?.firstname!,
                lastname: data?.lastname ? data.lastname : currentUser?.lastname!,
                phoneNumber: data.contactNumber ? data.contactNumber : currentUser?.phoneNumber!,
                genderId: data.genderId ? data.genderId : currentUser?.genderId!,
            }
            updateAuthenticationField("user", updatedUser);
            onSuccess && onSuccess(data);
            sendRegisterAccInfo((data.areaCode! + data.contactNumber!)!.trim())
        } catch (error) {
            console.error("Send additional data user failed:", error);
        }
    };

    const selectGender = async (gender: GenderEnum) => {
        methods.setValue('genderId', gender)
        await methods.trigger('genderId')
    }
    const getSelectedGender = () => methods.getValues('genderId')

    return (
        <FormProvider {...methods}>
            <form ref={formRef} onSubmit={methods.handleSubmit(handleSendBasicInfo)}
                  className="flex flex-col gap-y-6 p-4 max-w-md mx-auto h-full min-h-fit">

                <div className='flex-grow md:flex-grow-0 space-y-4'>
                    <div className="flex items-center gap-3 justify-between">
                        <h2 className="text-[1.75rem] md:text-[2.5rem] font-bold">Get free $1</h2>
                        <span className='inline-block md:hidden'><SkipStepVerification
                            onClick={onSuccess?.bind(this, methods.getValues())}/></span>
                    </div>

                    <span className='text-special-gray text-sm md:text-base'>
                        Fill the phone number below to get free $.
                    </span>

                    <PhoneNumberFormField nameNumber="contactNumber" nameCode="areaCode" label="Phone number"
                                          placeholder="+44"/>
                    <SelectorGender selectedGender={getSelectedGender()} onSelect={selectGender}/>
                    <TextFormField name="firstname" label="Name (optional)" placeholder="ex. John"/>
                    <TextFormField name="lastname" label="Surname (optional)" placeholder="ex. Doe"/>
                    <SelectFormField name="countryId" label="Country (optional)" labelKey='name' valueKey='id'
                                     fetchOptions={getCountriesMemoized} parentWrapperRef={formRef} searchable={true}/>
                </div>

                <AppButton
                    type='submit'
                    loading={loading}
                    label="Get free $1"
                    className='w-full text-white text-sm py-[9px] h-fit font-semibold bg-primary rounded-2xl'/>
            </form>
        </FormProvider>
    );
}

export default AdditionalAccountDetailsForm
