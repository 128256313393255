import React, {createContext, useEffect, useState} from 'react';
import {AuthContextType} from "../models/interfaces/auth-context-type.interface";
import {AuthProviderProps} from "../models/interfaces/auth-provider-props.interface";
import AuthService from "../services/auth.service";
import authService from "../services/auth.service";
import {AxiosError} from "axios";
import {useNavigate} from "react-router-dom";
import LocalStorageService from "../../common/services/local-storage.service";
import {LocalStorageKeys} from "../../common/models/enums/local-storage-keys.enum";
import {ResetPasswordBodyRequest} from "../models/interfaces/reset-email-body.interface";
import {AuthenticatedUser} from "../models/interfaces/authenticated-user.interface";
import {ChangePasswordBody, ChangePasswordBodyRequest} from "../models/interfaces/change-password-body.interface";
import {Authentication} from "../models/interfaces/authentication.interface";
import {RegisterBody} from "../models/interfaces/register-body.interface";
import {UserAuthority} from "../models/enums/user-authority.enum";
import {AuthenticatedUserResponse} from "../models/interfaces/authenticated-user-response-interface";
import {useAnalytics} from "../../core/hooks/use-analytics";
import {useBecomeCreatorApi} from "../../core/hooks/use-become-creator-api";
import useEffectOnce from "../../common/hooks/use-effect-once";
import {CheckStatusPromotionRequest} from "../../core/models/interfaces/check-status-promotion-request.interface";
import {useAnnouncement} from "../../../layout/hooks/use-annoucement.hook";
import RequestedNotificationTokenService from "../../../firebase/requested-notification-token.service";

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

const AuthProvider: React.FC<AuthProviderProps> = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(AuthService.isAuthenticated());
    const [currentUser, setCurrentUser] = useState<AuthenticatedUser | undefined>(AuthService.authenticatedUser);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<AxiosError | null>(null);
    const navigate = useNavigate();
    const {sendUserInfo} = useAnalytics();
    const {checkStatusPromotionRequest} = useBecomeCreatorApi();
    const {setAnnouncement} = useAnnouncement();

    const login = async (email: string, password: string): Promise<void> => {
        setLoading(true);
        setError(null);

        return await AuthService.authenticate(email, password)
            .then(async (): Promise<void> => {
                setCurrentUser(AuthService.authenticatedUser!)
                setIsAuthenticated(true);
                sendUserInfo(AuthService.authenticatedUser!);
            })
            .then(() => {
                RequestedNotificationTokenService.removeNotificationRequested(AuthService.authenticatedUser?.id!)
            })
            .catch((err): void => {
                setError(err);
                setIsAuthenticated(false);
                throw err;
            })
            .finally((): void => {
                setLoading(false)
            })
    };

    const handleAdminTokenLogin = async (token: string): Promise<void> => {
        setLoading(true);
        setError(null);

        return await AuthService.handleAdminTokenLogin(token)
            .then((res: AuthenticatedUserResponse | void): void => {
                res && setCurrentUser(res);
                setIsAuthenticated(true);
            })
            .catch((err): void => {
                setError(err);
                setCurrentUser(undefined);
                setIsAuthenticated(false);
                throw err;
            })
            .finally((): void => {
                setLoading(false)
            })
    }

    const register = async (body: RegisterBody) => {
        setLoading(true);
        setError(null);

        return await AuthService.registerAccount(body)
            .catch((err): void => {
                setError(err);
                throw err;
            })
            .finally((): void => {
                setLoading(false)
            })
    };

    const logout = async () => {
        await AuthService.logout();
        navigate('/welcome', {replace: true})
    };

    const getTokenToResetPassword = async (email: string) => {
        setLoading(true);
        setError(null);

        return await AuthService.getTokenResetPassword(email)
            .catch((err): void => {
                setError(err);
                throw err;
            })
            .finally((): void => {
                setLoading(false)
            })
    }

    const verifyTokenToResetPassword = async (token: string, emailOrUsername: string): Promise<void> => {
        setLoading(true);
        setError(null);

        return await AuthService.sendTokenResetPasswordToVerify(token, emailOrUsername)
            .catch((err): void => {
                setError(err);
                throw err;
            })
            .finally((): void => {
                setLoading(false)
            })
    }

    const resetPassword = async (newPassword: string): Promise<void> => {
        setLoading(true);
        setError(null);

        const body: ResetPasswordBodyRequest = {
            email: LocalStorageService.get(LocalStorageKeys.EMAIL_OR_USERNAME_RESET_PASSWORD),
            token: LocalStorageService.get(LocalStorageKeys.TOKEN_RESET_PASSWORD),
            newPassword
        }

        return await AuthService.resetPassword(body)
            .then((): void => {
                navigate('/login', {replace: true})
            })
            .catch((err): void => {
                setError(err);
                throw err;
            })
            .finally((): void => {
                setLoading(false)
            })
    }

    const changePassword = async (body: ChangePasswordBody): Promise<void> => {
        setLoading(true);
        setError(null);

        const mappedBody: ChangePasswordBodyRequest = {
            currentPassword: body.currentPassword,
            newPassword: body.newPassword
        }

        return await AuthService.changePassword(mappedBody)
            .then()
            .catch((err): void => {
                setError(err);
                throw err;
            })
            .finally((): void => {
                setLoading(false)
            })
    }

    const sendEmailConfirmationToken = async (email: string): Promise<void | AxiosError> => {
        setLoading(true);
        setError(null);

        return await AuthService.sendEmailConfirmationToken(email)
            .catch((err: AxiosError) => {
                setError(err);
                throw err;
            })
            .finally((): void => {
                setLoading(false)
            })
    }

    const activateAccount = async (token: string): Promise<void | AxiosError> => {
        setLoading(true);
        setError(null);

        return await AuthService.activateAccount(token)
            .then()
            .catch((err: AxiosError) => {
                setError(err);
                throw err;
            })
            .finally((): void => {
                setLoading(false)
            })
    }

    const updateAuthenticationField = <K extends keyof Authentication>(key: K, value: Authentication[K]) => {
        if (key === 'user' && typeof value === 'object' && value !== null) {
            const {profileImage, backgroundProfileImage, ...authenticatedUserToSave} = value as AuthenticatedUser;
            setCurrentUser(value as AuthenticatedUser);
            AuthService.updateAuthenticationField(key, authenticatedUserToSave as Authentication[K]);
        } else {
            AuthService.updateAuthenticationField(key, value);
        }
    };

    const hasAnyAuthorities = (requiredRoles: UserAuthority[]) => {
        const rolesLoggedUser = [currentUser?.authority];
        if (rolesLoggedUser.length) {
            return requiredRoles.some(role => rolesLoggedUser.includes(role));
        } else {
            return false;
        }
    }

    const reloadSessionOnAcceptedPromotionRequest = async (): Promise<void> => {
        const data: CheckStatusPromotionRequest = await checkStatusPromotionRequest();
        if (data.authority === UserAuthority.CONTENT_CREATOR && currentUser?.authority === UserAuthority.FAN) {
            updateAuthenticationField("token", {
                value: data.token!,
                validUntil: authService.prepareTokenValidUntil(data.tokenValidInMinutes!)
            })
            updateAuthenticationField("user", {
                ...currentUser!,
                authority: data.authority,
            })
            setAnnouncement("Your request to become a Creator has been approved! 🎉 You now have access to Creator Tools.");
        }
    }

    useEffect(() => {
        AuthService.setLogoutCallback(() => {
            setIsAuthenticated(false);
            setCurrentUser(undefined);
        });

        return () => {
            AuthService.setLogoutCallback(null);
        };
    }, []);

    useEffect(() => {
        const handleImageCacheUpdated = () => {
            const updatedUser = AuthService.authenticatedUser;
            setCurrentUser(updatedUser);
        };

        AuthService.onImageCacheUpdated(handleImageCacheUpdated);
        return () => AuthService.offImageCacheUpdated(handleImageCacheUpdated);
    }, []);

    useEffectOnce(() => {
        (currentUser?.pendingPromotion && currentUser.authority === UserAuthority.FAN) && reloadSessionOnAcceptedPromotionRequest();
    })

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                handleAdminTokenLogin,
                login,
                logout,
                getTokenToResetPassword,
                resetPassword,
                changePassword,
                register,
                loading,
                error,
                currentUser,
                setCurrentUser,
                updateAuthenticationField,
                sendEmailConfirmationToken,
                activateAccount,
                hasAnyAuthorities,
                verifyTokenToResetPassword
            }}>
            {children}
        </AuthContext.Provider>
    )
};

export {AuthContext, AuthProvider}
