class CreditsConversionService {
    private readonly yCreditsToCentsRate: number = 80;

    convertYCreditsToDollarsWithCurrency(yCredits: number | null, ycInCents: number = this.yCreditsToCentsRate): string {
        let dollars: number = 0;
        if (yCredits) {
            const totalCents: number = yCredits * ycInCents;
            dollars = totalCents / 100;
        }
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(dollars);
    }

    convertYCreditsToDollars(yCredits: number | null, ycInCents: number = this.yCreditsToCentsRate): string {
        let dollars: number = 0;
        if (yCredits) {
            const totalCents: number = yCredits * ycInCents;
            dollars = totalCents / 100;
        }
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(dollars);
    }

    formatNumberWithSpaceSeparator(value: number): string {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
}

export default new CreditsConversionService();
