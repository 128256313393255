import React, {useState} from "react";
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import {navigation} from "./constants/menu";
import {useSidebar} from "./hooks/use-sidebar";
import useAuth from "../features/auth/hooks/use-auth";
import AppButton from "../features/common/components/app-button";
import {ReactComponent as ChevronLeftIcon, ReactComponent as ChevronRightIcon} from "@assets/icons/vector.svg";
import {Base64Prefix} from "../features/common/constats/base64-prefix";
import {classNames} from "../utils/class-names";
import LabelUserRole from "../features/core/components/ui/label-user-role";
import DefaultProfileImg from "../features/common/components/default-profile-img";
import {ArrowLeftOnRectangleIcon, HeartIcon} from "@heroicons/react/24/outline";
import {UserAuthority} from "../features/auth/models/enums/user-authority.enum";
import {badgeComponentMapper} from "../features/core/helpers/badge-component-mapper";

const shortLogo = require("../assets/images/main-logo-short.png");
const mainLogo = require("../assets/images/main-logo.png");

const AppMenu = () => {
    const location = useLocation();
    const {closeSidebar} = useSidebar();
    const {logout, currentUser, hasAnyAuthorities} = useAuth();
    const navigate = useNavigate();
    const [isCollapsedDesktopMenu, setIsCollapsedDesktopMenu] = useState(true);

    const presentedNameUser = !currentUser?.firstname || !currentUser?.lastname ? currentUser?.username! : currentUser.firstname + " " + currentUser?.lastname

    const determineIsActive = (
        itemHref: string,
        locationPathname: string,
        navigationItems: typeof navigation
    ): boolean => {
        const matchingNavItems = navigationItems.filter(navItem =>
            locationPathname.startsWith(navItem.href)
        );

        if (matchingNavItems.length === 0) {
            return false;
        }

        const mostSpecificNavItem = matchingNavItems.reduce((prev, current) =>
            prev.href.length > current.href.length ? prev : current
        );

        return itemHref === mostSpecificNavItem.href;
    };

    return (
        <div
            className={`h-full flex-grow max-w-full ${isCollapsedDesktopMenu ? 'lg:min-w-[300px] lg:w-[300px]' : 'lg:min-w-[111px] lg:w-[111px]'} min-w-full`}>
            <div className={`p-4 lg:border-r border-special-gray flex flex-col lg:flex-col-reverse overflow-x-hidden 
            lg:overflow-x-visible h-full flex-grow ${isCollapsedDesktopMenu ? 'lg:min-w-[300px] lg:w-[300px]' : 'lg:min-w-[96px] lg:w-[96px]'}`}>
                <div className={classNames(
                    'flex flex-col !justify-center gap-1',
                )}>
                    <div className={classNames(
                        `flex items-start space-x-4 p-2 max-w-full`,
                        !isCollapsedDesktopMenu && 'justify-center px-0'
                    )}>
                        <Link to='/app/my-profile' onClick={closeSidebar}>
                            {currentUser?.profileImage ? (
                                <img
                                    className="h-[72px] w-[72px] lg:w-[48px] lg:h-[48px] rounded-2xl object-cover"
                                    src={Base64Prefix + currentUser.profileImage}
                                    alt="User profile"
                                />

                            ) : (
                                <div
                                    className="h-[72px] w-[72px] lg:w-[48px] lg:h-[48px] rounded-2xl object-cover overflow-hidden">
                                    <DefaultProfileImg/>
                                </div>
                            )}
                        </Link>

                        {isCollapsedDesktopMenu && (
                            <div className='flex flex-col gap-1 justify-center h-full'>
                                <LabelUserRole role={currentUser?.authority}/>
                                <p className="text-[22px] lg:text-lg font-bold lg:leading-5 break-all" translate="no">
                                    {presentedNameUser}
                                </p>
                            </div>
                        )}
                    </div>
                    <div className={classNames(
                        "flex gap-2",
                        !isCollapsedDesktopMenu && "flex-col items-center"
                    )}>
                        {hasAnyAuthorities([UserAuthority.FAN]) && (
                            <AppButton
                                label=''
                                onClick={() => navigate("/app/become-creator")}
                                className={classNames(
                                    'hover:text-primary text-gray font-semibold py-[9px] text-sm focus:text-primary ' +
                                    'w-full !rounded-2xl flex-grow',
                                    !isCollapsedDesktopMenu && "!w-12 !h-12 !px-4 !py-3"
                                )}>
                                {isCollapsedDesktopMenu && 'Become a Creator'}
                                {!isCollapsedDesktopMenu && <HeartIcon className='w-5 h-5'/>}
                            </AppButton>
                        )}
                        <AppButton
                            label=''
                            onClick={logout}
                            className={classNames(
                                'hidden lg:block whitespace-nowrap border-gray border text-special-gray font-semibold hover:text-primary ' +
                                'focus:text-primary py-[9px] !rounded-2xl text-sm',
                                !isCollapsedDesktopMenu && "!w-12 !h-12 !px-4 !py-3",
                                hasAnyAuthorities([UserAuthority.FAN]) ? "w-fit ": "w-full"
                            )}>
                            {isCollapsedDesktopMenu && 'Log out'}
                            {!isCollapsedDesktopMenu && <ArrowLeftOnRectangleIcon className='w-5 h-5'/>}
                        </AppButton>
                    </div>
                </div>

                <ul className="mt-8 space-y-4 flex-grow ">
                    {navigation
                        .filter(i => hasAnyAuthorities(i.accessRoles))
                        .map((item, index) => {
                                const isActive = determineIsActive(item.href, location.pathname, navigation);
                                return (
                                    <div key={item.name} className={classNames(
                                        'relative flex justify-between items-center',
                                        !isCollapsedDesktopMenu && "lg:justify-center"
                                    )}>
                                        <NavLink
                                            key={item.name}
                                            to={item.href}
                                            end
                                            onClick={closeSidebar}
                                            className={classNames(
                                                "relative flex justify-between min-h-[40px] items-center text-gray font-semibold hover:bg-gray-100 p-2 rounded-lg",
                                                isCollapsedDesktopMenu && "w-full"
                                            )}>
                                            {isActive && (
                                                <div className={classNames(
                                                    'absolute bg-primary rounded-full w-[50px] h-[50px]',
                                                    !isCollapsedDesktopMenu ? 'left-[-68px] top-[-5px]' : 'left-[-54px] top-[-5px]'
                                                )}></div>
                                            )}
                                            <div className="flex items-center space-x-3 ">
                                                <item.Icon className={`h-5 w-5 ${isActive && 'text-black'}`}/>
                                                {isCollapsedDesktopMenu && <span className='text-base'>{item.name}</span>}
                                            </div>
                                        </NavLink>
                                        <div className={classNames(
                                            "mr-2",
                                            !isCollapsedDesktopMenu ? "absolute top-1/2 right-[-28px] translate-y-[-50%]" : ""
                                        )}>
                                            {badgeComponentMapper(item.href)}
                                        </div>
                                    </div>
                                )
                            }
                        )}
                </ul>

                <div className="p-2 block lg:hidden" onClick={logout}>
                    <AppButton label='Log out'
                               className='w-full border-special-gray border text-special-gray font-semibold hover:text-primary
                           focus:text-primary py-[9px] !rounded-2xl text-sm'/>
                </div>

                <div className="px-2 hidden lg:flex items-center justify-between relative w-full">
                    <div className={classNames(
                        'flex justify-center',
                        !isCollapsedDesktopMenu && "w-full"
                    )}>
                        <img
                            alt='logo yurs'
                            className={classNames(
                                'h-14', !isCollapsedDesktopMenu && "ml-1"
                            )}
                            src={isCollapsedDesktopMenu ? mainLogo : shortLogo}/>
                    </div>
                    {isCollapsedDesktopMenu ? (
                        <div onClick={() => setIsCollapsedDesktopMenu(prev => !prev)}
                             className='border border-special-gray w-8 h-8 flex items-center justify-center cursor-pointer
                            text-special-gray hover:text-primary-darken rounded-xl'>
                            <ChevronLeftIcon className='w-[0.625rem] h-[0.625rem]'/>
                        </div>
                    ) : (
                        <div onClick={() => setIsCollapsedDesktopMenu(prev => !prev)}
                             className={classNames(
                                 'border border-special-gray w-8 h-8 flex items-center justify-center cursor-pointer',
                                 'text-special-gray hover:text-primary-darken rounded-xl',
                                 !isCollapsedDesktopMenu && 'absolute top-1/2 right-[-32px] bg-white translate-y-[-50%]'
                             )}>
                            <ChevronRightIcon className='w-[0.625rem] h-[0.625rem] rotate-180'/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AppMenu;
