import React, {FormEvent, useContext} from 'react';
import {FormProvider} from 'react-hook-form';
import NumberFormField from "../../common/components/forms/number-form-field";
import DescriptionFormField from "../../common/components/forms/description-form-field";
import CreditsConversionService from "../../purchases/services/credits-conversion.service";
import Tooltip from "../../common/components/tooltip";
import {useFormDigitalProduct} from "../hooks/use-form-digital-product.hook";
import {classNames} from "../../../utils/class-names";
import {removeProtocol} from "../../../utils/extract-base-url";
import {ReactComponent as LinkIcon} from "@assets/icons/link.svg";
import ProductImageUploader from "./product-image-uploader";
import MultiFileUploader from "./multi-file-uploader";
import {TempProductFile} from "../models/interfaces/product-file.interface";
import useAuth from "../../auth/hooks/use-auth";
import {v4 as uuidv4} from 'uuid';
import SelectFormField from "../../common/components/forms/select-form-field";
import {SessionCreatorToolsContext} from "../contexts/session-creator-tools-context";
import {useSidebar} from "../../../layout/hooks/use-sidebar";
import {SidebarIds} from "../../../layout/types/sidebar-ids.interface";

interface ProductFormProps {
    classNameForm?: string;
    hideAddCategoryButton?: boolean;
}

const ProductForm = (props: ProductFormProps) => {
    const {classNameForm, hideAddCategoryButton} = props;
    const {currentUser} = useAuth();
    const {productCategories} = useContext(SessionCreatorToolsContext)!;
    const {methods, loading, removeFile, addNewFiles, isSubmittingSuccessful, isDisabled} = useFormDigitalProduct();
    const {openSidebar} = useSidebar();

    const urlToProduct = `${removeProtocol(window.location.origin)}/${currentUser?.username}/${methods.getValues('aliasName')}`

    const handleOpenSidebarToAddCategory = () => openSidebar(SidebarIds.ADD_NEW_PRODUCT_CATEGORY)
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => e.preventDefault();
    const handlePriceChange = (e: any) =>  {
        console.log(1)
        methods.setValue('price', parseInt(e.target.value, 10), {shouldDirty: true})
    }
    const handleProductFilesChanges = (files: TempProductFile[]) => addNewFiles(files)
    const handleProductPictureChange = async (file: File | null) => {
        /**
         * Creating new file as any type and add extend object by flag 'isNew' for make form control as dirty
         */
        const newFile: any = file ? new File([file], file.name || "", {type: file.type}) : null;
        newFile && (newFile.isNew = newFile.isNew = `new-${uuidv4()}`);
        methods.setValue('productPicture', newFile, {shouldDirty: true, shouldTouch: true, shouldValidate: true});
        await methods.trigger('productPicture');
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit} className={classNames(
                'flex flex-col gap-3',
                classNameForm && classNameForm
            )}>

                <ProductImageUploader
                    forceDisabled={isDisabled || loading}
                    isSubmittingSuccessful={isSubmittingSuccessful}
                    isSubmitting={methods.formState.isSubmitting}
                    imageFile={methods.getValues('productPicture')}
                    label="Product cover"
                    onImageChange={handleProductPictureChange}
                />
                <div className='space-y-2'>
                    <DescriptionFormField
                        forceDisabled={isDisabled}
                        isLoading={loading}
                        maxLength={100}
                        name="name"
                        label="name"
                        placeholder="Enter name"/>
                    <span className='flex gap-1 text-special-gray'>
                        <LinkIcon className='w-3 min-w-3 h-3'/>
                        <span className='truncate flex-shrink leading-[0.9rem] text-xs font-medium'>
                             {urlToProduct}
                        </span>
                    </span>
                </div>

                <div className='text-right'>
                    <SelectFormField
                        forceDisabled={isDisabled}
                        isLoading={loading}
                        searchable={true}
                        name='categoryId'
                        label='Category'
                        labelKey="name"
                        valueKey="id"
                        placeholder="Select category"
                        options={productCategories.sort((a, b) => a.name!.localeCompare(b.name!))}
                    />
                    {!hideAddCategoryButton
                        &&
                        <span onClick={handleOpenSidebarToAddCategory}
                              className='text-special-gray font-semibold text-sm hover:text-primary-darken cursor-pointer'>
                            + Add new category
                        </span>
                    }
                </div>

                <DescriptionFormField
                    forceDisabled={isDisabled}
                    isLoading={loading}
                    name="description"
                    maxLength={1000}
                    label="Description"
                    placeholder="Enter description"/>

                <MultiFileUploader
                    forceDisabled={isDisabled || loading}
                    isSubmittingSuccessful={isSubmittingSuccessful}
                    isSubmitting={methods.formState.isSubmitting}
                    errors={methods.formState.errors}
                    label='Files'
                    onRemoveFile={removeFile}
                    initialFiles={methods?.getValues('files') || []}
                    onFilesChange={handleProductFilesChanges}/>

                <div className='space-y-4'>
                    <header className='text-lg font-semibold'>Settings</header>
                    <div>
                        <span className='font-semibold text-[1rem]'>
                            Price
                            <Tooltip classNameIcon='relative top-[0.1rem] text-special-gray text-[0.85rem]'/>
                        </span>
                        <NumberFormField
                            forceDisabled={isDisabled}
                            isLoading={loading}
                            name="price"
                            label="Price for all fans"
                            showCoin={true}
                            min={0}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            onChange={handlePriceChange}
                            />

                        <span className="text-gray-500 text-xs text-gray mx-2">
                            Approximately {CreditsConversionService.convertYCreditsToDollarsWithCurrency(methods.watch('price'))}
                        </span>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};
export default ProductForm;
