import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import AppWrapper from "./components/app-wrapper";
import AppSidebarWrapper from "./components/app-sidebar-wrapper";
import {SidebarProvider} from "./contexts/sidebar-context";
import {SidebarIds} from "./types/sidebar-ids.interface";
import AppMenu from "./app-menu";
import WalletPage from "../pages/wallet-page";
import HomePage from "../pages/home-page";
import AppTopBarAnnouncement from "./components/app-top-bar-announcement";
import ProfilePage from "../pages/profile-page";
import MessagesPage from "../pages/messages-page";
import CreatorToolsPage from "../pages/creator-tools-page";
import SettingsPage from "../pages/settings-page";
import {UserAuthority} from "../features/auth/models/enums/user-authority.enum";
import GuardRestrictedRoute from "../features/auth/components/guard-restricted-route";
import {useInitNotificationToken} from "../firebase/hooks/use-init-notification-token.hook";

const MainLayer = () => {
    useInitNotificationToken();

    return (
        <SidebarProvider>
            <div className={'w-full bg-white h-svh max-h-screen grid grid-flow-col grid-rows-[auto_1fr] overflow-hidden'}>
                <AppTopBarAnnouncement/>
                <div className='w-full flex overflow-hidden'>
                    <AppSidebarWrapper id={SidebarIds.MENU} side='left' className='lg:max-w-sm'>
                        <AppMenu/>
                    </AppSidebarWrapper>
                    <AppWrapper className='w-full flex-grow flex flex-col overflow-y-auto h-full'>
                        <Routes>
                            <Route index element={<HomePage/>}/>
                            <Route path='/wallet/*' element={<WalletPage/>}/>
                            <Route path='/settings/*' element={<SettingsPage/>}/>
                            <Route path='/my-profile/*' element={<ProfilePage/>}/>
                            <Route path='/cc-tools/*' element={
                                <GuardRestrictedRoute requiredRoles={[UserAuthority.CONTENT_CREATOR, UserAuthority.SUPPORT]}>
                                    <CreatorToolsPage/>
                                </GuardRestrictedRoute>
                            }/>
                            <Route path='/messages' element={<MessagesPage/>}/>
                            <Route path="*" element={<Navigate to="/not-found"/>}/>
                        </Routes>
                    </AppWrapper>
                </div>
            </div>
        </SidebarProvider>
    )
}
export default MainLayer
