import {AuthenticatedUser} from "../../auth/models/interfaces/authenticated-user.interface";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";

const useGuardOnBoarding = (currentUser: AuthenticatedUser | undefined): boolean => {
    switch (currentUser?.authority) {
        case UserAuthority.FAN:
            return !!currentUser.fanOnBoarding;
        case UserAuthority.CONTENT_CREATOR:
            return !!currentUser.ccOnBoarding;
        case UserAuthority.SUPPORT:
            return true;
        default:
            return false;
    }
};

export default useGuardOnBoarding;
